import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
const mapText = {
  recentActivity: ['活动预告', 'Upcoming Events', '活動預告'],
  noActivity: ['暂无活动', 'There are currently no events scheduled', '暫無活動'],
  pastActivity: ['以往活动', 'Past Events', '以往活動'],
  watchVideo: ['观看视频', 'Watch the Video', '觀看視頻'],
  listenMP3: ['收听音频', 'Listening to webcast', '收聽音訊'],
  view_link: ['公司演示文档', 'Company Presentation', '公司演示文檔']
};
export default {
  name: '',
  data() {
    let yearArr = [];
    for (let i = new Date().getFullYear(); i > new Date().getFullYear() - 10; i--) {
      if (i > 2022) yearArr.push(i);
    }
    return {
      companyInfo: {},
      recentActivity: [],
      pastActivity: [],
      yearArr,
      mapText,
      title: this.$api.api_title,
      activity_year: yearArr[0]
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      console.log("route.name" + route.name);
      return route.name;
    },
    ...mapState({
      irMenuData: state => state.irMenuData,
      device: state => state.device,
      language: state => state.language
    })
  },
  created() {
    this.getData();
  },
  mounted() {
    document.oncontextmenu = () => {
      if (this.device == 'mobile') return false;
    };
  },
  methods: {
    getData() {
      const params_2024 = {
        eventType: 1,
        nhLanguage: 1,
        year: 2024
      };
      const params_2023 = {
        eventType: 1,
        nhLanguage: 1,
        year: 2023
      };
      // 查询以往的活动信息
      this.$http.post(this.$api.investment_eventList, params_2024).then(res => {
        if (res.code == 0 && res.rows) {
          this.pastActivity = [...this.pastActivity, ...res.rows];
          this.pastActivity.forEach(item => {
            if (item.sendTime) {
              item.year = item.sendTime.slice(0, 4);
              item.month = item.sendTime.slice(5, 7);
              item.day = item.sendTime.slice(8, 10);
              item.time = item.sendTime.slice(11, 16);
            }
          });
        }
        this.$http.post(this.$api.investment_eventList, params_2023).then(res => {
          if (res.code == 0 && res.rows) {
            this.pastActivity = [...this.pastActivity, ...res.rows];
            this.pastActivity.forEach(item => {
              if (item.sendTime) {
                item.year = item.sendTime.slice(0, 4);
                item.month = item.sendTime.slice(5, 7);
                item.day = item.sendTime.slice(8, 10);
                item.time = item.sendTime.slice(11, 16);
              }
            });
          }
        });
      });
      const params_future = {
        eventType: 0,
        nhLanguage: parseInt(this.language)
      };
      // 查询即将举行的活动信息
      this.$http.post(this.$api.investment_eventList, params_future).then(res => {
        if (res.code == 0 && res.rows) this.recentActivity = res.rows;
      });
    },
    change() {
      const params = {
        eventType: 1,
        nhLanguage: 1,
        year: this.activity_year
      };
      this.$http.post(this.$api.investment_eventList, params).then(res => {
        if (res.code == 0) {
          if (res.rows) {
            this.pastActivity = res.rows;
          }
        }
      });
    },
    toDetail(path, eventItem) {
      if (!eventItem.eventLink) return;
      this.$router.push({
        path: path,
        query: eventItem
      });
    },
    viewPdf(link) {
      window.open(this.title + link + '#toolbar=0', '_blank');
    },
    viewPdf2(link) {
      window.open(link + '#toolbar=0', '_blank');
    }
  }
};